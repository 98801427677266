import { render, staticRenderFns } from "./aboutUs.vue?vue&type=template&id=ebd3c6d0&scoped=true&"
import script from "./aboutUs.vue?vue&type=script&lang=js&"
export * from "./aboutUs.vue?vue&type=script&lang=js&"
import style0 from "../../templateAssests/css/home.css?vue&type=style&index=0&id=ebd3c6d0&scoped=true&lang=css&"
import style1 from "./aboutUs.vue?vue&type=style&index=1&id=ebd3c6d0&scoped=true&lang=css&"
import style2 from "./aboutUs.vue?vue&type=style&index=2&id=ebd3c6d0&scoped=true&lang=css&"
import style3 from "./aboutUs.vue?vue&type=style&index=3&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd3c6d0",
  null
  
)

export default component.exports