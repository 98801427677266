<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import loginRegModal from "../popUpModals/loginReg";
import termsAndCondition from "../popUpModals/termsAndCondition";
import footerLayout from "../../layouts/footerLayout";
import appConfig from "@/app.config";
import ClassOnScroll from 'vue-class-on-scroll'
import simplebar from "simplebar-vue";

import Drawer from "vue-simple-drawer"

import jwt_decode from "jwt-decode";
 



import PincodeInput from 'vue-pincode-input';

import VueCountdown from '@chenfengyuan/vue-countdown';

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue';
// import registerCopyVue from '../account/register - Copy.vue';

export default {
  page: {
    title: "Cloud Kitch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    loginRegModal,
    termsAndCondition,
    footerLayout,
	simplebar,
    PincodeInput,
    VueCountdown,
    VueGoogleAutocomplete,
    Drawer
  },
  directives: {
  'class-on-scroll': ClassOnScroll,
},
 data() {
        return {
            fullName:'',
            emailID:'',
            RestName:'',
            RestAddress:'',
            city:'',
            country:'',
            message:'',
            emPanelError:'',
		   openMenuDrawer: false,
            emailIDforReg:'',
            searching: 0,
            changedInputValue:'',
            isValNum:0,
            countryCode:'+91',
            email:'',
            contact:'',
            code:'',
             show: false,
             counting: false,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
            isHidden: true,
            authError: '',
            isAuthError: false,
            regName:'',
            regEmail:'',
            regCorporateCode:'',
            regerror:'',
            hiddenOTPReg: true,
            codeReg: '',
            corporatesBranches: undefined,
            selectedBranchID:'',
            copBranchError:'',
            regSendOTPData: 0,
            regVerifyOTPData: 0,
            sendOTPData: 0,
            verifyOTPData: 0,
            userName:'',
            localStorageUserData: '',
            params: {
                    client_id: "855252858658-sihf66i67ut7cdul3hfm132b95r8agct.apps.googleusercontent.com"
                },
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
        }
     }, 
     mounted()
     {
        this.getUserData();
        
        this.autoRefreshToken();
     },
methods: {

    addEmpanel()
		{
			let getEmpanelDetails = JSON.stringify({ 
				fullName: this.fullName,
                emailID: this.emailID,
                RestName:this.RestName,
                RestAddress:this.RestAddress,
                city:this.city,
                country:this.country,
                message:this.message
				});
			this.axios.post('/insertEmpanel', getEmpanelDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                location.reload();
            }
            else if(status == 200 && response.data.status == 204)
            {
                this.emPanelError = response.data.message;
            }
            }).catch((error) => {
			    console.log(error.response.data.message);
			});
		},

    autoRefreshToken()
		{
			if (localStorage.getItem('uTid') && localStorage.getItem('uTid') != null) 
			{
				var getExp = jwt_decode(localStorage.getItem('uTid'));
				var currentDate  = new Date();
				var tokenExpire = new Date(getExp.exp * 1000);
				var difference = tokenExpire.getTime() - currentDate.getTime(); // This will give difference in milliseconds;
				var resultInMinutes = Math.round(difference / 60000);
				if(resultInMinutes <= 15 && resultInMinutes >= 0)
				{
					this.refreshTokens();
				}
				else if (resultInMinutes < 0) {
                    this.refreshTokens();
					// localStorage.clear();
					// Vue.prototype.$userData = '';
					// Vue.prototype.$placesAddress = '';
					// // this.$router.push('/');
					// window.location.href = '/';
				}
			}
		},

		refreshTokens()
		{
			// alert();
			 this.axios.get('/autoRefreshToken')
                    .then((response)=>{
					// console.log(response);
					let status = response.status;
					if(status == 200 && response.data.status == 200 && response.data.refreshedToken && response.data.refreshedToken != '')
					{
						console.log(response.data.refreshedToken);
						var userData = {
                                "utid":response.data.refreshedToken,
                            }
                            localStorage.setItem('uTid',JSON.stringify(userData));
							location.reload();
					} // if token is expired so it can be refreshed
					}).catch((error) => {
						// console.log(error);
				});
		},

    toggleMenuDrawer() {
			this.openMenuDrawer = !this.openMenuDrawer
		},
        openLoginModal()
		{
			this.openMenuDrawer = !this.openMenuDrawer;
			this.$bvModal.show("modal-login");

		},
    getUserData()
    {
        this.axios.post('/getUserDeatils')
                    .then((response)=>{
					// console.log(response);
                    // if(response.data.token && response.data.token != '')
					// {
					// 	var userData = {
                    //             "utid":response.data.token,
                    //         }
                    //         localStorage.setItem('uTid',JSON.stringify(userData));
					// } // if token is expired so it can be refreshed
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						if (response.data.userData.userName) {
                            this.userName = response.data.userData.userName;
                        }
                        else
                        {
                            this.userName = '';
                        }
                        if(response.data.userData.isTncAgreed == 0)
                        {
                            this.$bvModal.show("modal-termsCondition");
                        }
					}
                    else
                    {
                        // localStorage.clear();
                    }
					// this.cartStatus = response.data.cartdetails;
				}).catch((error) => {
							// localStorage.clear();
						console.log(error.response.data.message);
				});
    },
    logout()
		{
            this.$confirm("Are you sure you want to logout?","Logout","")
				.then(() => {
                                localStorage.clear();
                                Vue.prototype.$userData = '';
                                Vue.prototype.$placesAddress = '';
                                // this.$router.push('/');
                                location.reload();
                });
		},

  },
};
</script>


<template>
  <!-- <Layout> -->
    <div>
        <header class="header clearfix element_to_stick headerCk" v-class-on-scroll="{ class: 'sticky', offset: 200 }">
        <div class="container">
            <div class="row">
				<div class="col-md-4 col-lg-4 col-xl-4 noMobile noMobilenew">
            <div id="logo">
                <div class="contact" v-class-on-scroll="{ class: 'contactSticky', offset: 200 }">
                   <!-- <img src="img/logo.svg" width="162" height="35" alt="" class="logo_normal">
                    <img src="img/logo_sticky.svg" width="162" height="35" alt="" class="logo_sticky"> -->
                    <i class="icon_phone"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
                </div>
            </div>
            </div>
				<div class="col-md-4 col-lg-4 col-xl-4 align-center">
					<div id="mainLogo logo">
                        <a href="/" class="noMobile">
                            <img src="../../templateAssests/img/cklogo.png"  v-class-on-scroll="{ class: 'mainLogoHide', offset: 10 }" alt="" style="object-fit:contain; height:55px">
                        </a>
						<a href="/">
							<img class="mainLogoImg mainLogoHide" v-class-on-scroll="{ class: 'mainLogoShow', offset: 200 }" src="../../templateAssests/img/cklogo.png" height="55" alt="">
						</a>
					</div>
				</div>
            <!-- /top_menu -->
            <a href="#0" class="login_reg align-left" style="top:33px;">
                <i class="icon_phone" style="color:#fff;"></i><a href="tel:+917710999666" class="contactAnch anchor"><span> +91 771 099 9666</span></a>
            </a>
           
            <a class="login_reg align-right" v-class-on-scroll="{ class: 'hideLogin_reg', offset: 200 }">
                <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu" v-if="userName != ''">
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
                <nav v-else>
                    <a href="/" class="show-submenu loginMob poppins">HOME</a>
                    <a href="#0" class="show-submenu loginMob poppins">CONTACT US</a>
                    <!-- <a class="show-submenu regMob" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                </nav>
            </a>
<!-- /Mobile view -->

            <div class="col-md-4 col-lg-4 col-xl-4 align-right noMobile">
            <b-dropdown
					variant="white"
					class="dropdown d-inline-block"
					toggle-class="header-item noti-icon"
					right
					menu-class="dropdown-menu-lg p-0 dropdown-menu-end user-menu" v-if="userName != ''">
					<template v-slot:button-content>
					<ul id="top_menu" class="drop_user">
						<li>
							<div class="dropdown user clearfix">
								<a href="#" data-toggle="dropdown">
									<figure><img src="https://assets.cloudkitch.co.in/user_documents/avatar.jpg" height="40" width="40" alt=""></figure><span class="userName" v-class-on-scroll="{ class: 'userNameSticky', offset: 200 }">{{userName}}</span>
								</a>
							</div>
						</li>
					</ul>
					</template>
					<simplebar style="max-height: 230px; max-width:144px;" data-simplebar>
					<div>
									<div>
										<ul class="dropdown-ul">
											<!-- <li><a href="#0"><i class="icon_cog"></i>Setting</a></li> -->
                                            <li><a href="/profile/profileDashBoard"><i class="icon_profile"></i>Profile</a></li>
											<li><a @click="logout"><i class="icon_key"></i>Log out</a></li>
										</ul>
									</div>
								</div>
					</simplebar>
					
					</b-dropdown>
					<nav class="main-menu" v-else>
					<ul>
                    <li class="submenu">
                        <a href="/" class="show-submenu poppins">HOME</a>
                        <!-- <a href="/login" class="show-submenu">Login</a> -->
                    </li>
                    <li class="submenu">
                        <a href="#0" class="show-submenu poppins">CONTACT US</a>
                        <!-- <a class="show-submenu" @click="$bvModal.hide('modal-login')" v-b-modal.modal-register><button class="btn_1 gradient" type="submit">Register</button></a> -->
                        <!-- <a href="/register" class="show-submenu"><button class="btn_1 gradient" type="submit">Register</button></a> -->
                    </li>
                </ul>
					</nav>
            </div>
            </div>
        </div>
    </header>
    <!-- /header -->

    
    <main>
        <div class="header-video">
            <div id="hero_video">
                <!-- <div class="shape_element one"></div>
                <div class="shape_element two"></div> -->
                <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container displayText">
                        <div class="row ">
                            <div class="col-xl-10 col-lg-10 col-md-10">
                              <!-- <img src="../../templateAssests/img/cklogo.png" alt="" class="ckLogo lazy"> -->
                                

                                <span class="bannerTitle" >Our vision is to be the </span><br>
                                <span class="bannerclrfont" style="color:#f3766a">largest food service distribution platform</span> 
                                <br><span class="bannerTitle">for corporates & enterprise supply chains.</span>
                                <br><br>
                                <p class="bannerDesc mb-2">We achieve this by aggregating food service companies, cloud kitchens & restaurants.</p>
		                        <!-- <button type="submit" class="btn_1 mb_5" style="padding:14px 24px;font-size:16px;">Register</button> -->
                                <!-- <p class="text-center mt-3 d-block"><a href="#emPanelForm" class="btn_1 medium gradient pulse_bt mt-2">Register Now</a></p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img id="teaser-video" class="teaser-video webVideo" src="../../templateAssests/img/about-banner.jpg">
            <!-- <img src="../../templateAssests/img/video_fix.png" class="header-video--media" data-video-src="video/intro" data-teaser-source="video/intro" data-provider="" data-video-width="1920" data-video-height="960"> -->
            <!-- <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video webVideo"><source src="../../templateAssests/video/intro_web_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video>
            <video autoplay="true" loop="loop" muted="" id="teaser-video" class="teaser-video mobVideo"><source src="../../templateAssests/video/intro_web_mobile_video.mp4" type="video/mp4"><source src="video/intro.ogv" type="video/ogg"></video> -->
            <!-- <div class="wave hero"></div> -->
        </div>
        <!-- /header-video -->


        <div class="shape_element_2 bg_white"  style="padding:px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:50px 0 60px 0;" >
                    <div class="col-lg-6 colWidth" style="margin-right:auto;">
                        <h2 class="poppins " style="color: #212121 ;font-size: 24px; font-weight: 600 ;line-height:35px;">Proprietary food service 
                            <span style="color:#f3766a; font-weight: 600;"> distribution </span>
                             and ordering <span style="color:#f3766a; font-weight: 600;"> platform. </span></h2>
                             <h5 class="poppins foSection1_li_title" style="font-size: 19px; line-height:32px; margin-bottom:35px;">Digitize Corporate Food Services and Elevate Operational Efficiency.</h5>
                        <div class="row">
                           <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a ;font-weight:600;">Digital Transformation</h5>
                                <p class="foSection1_li_desc" >
                                   of food services to integrated food programs creating value & experience for employers & employees.
                                </p>
                            </div>
                            <!-- Digital Transformation end -->
                             <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a ;font-weight:600;">Aggregate Spare Capacity</h5>
                                <p class="foSection1_li_desc" >
                                   of restaurants, central kitchens, cloud kitchens to QSR’s. 
                                </p>
                            </div>
                            <!-- Aggregate Spare Capacity END -->
                            <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a;font-weight:600;">In-Built Expertise</h5>
                                <p class="foSection1_li_desc">
                                    for food service operators & corporates to manage their business seamlessly and efficiently.   
                                </p>
                            </div>
                            <!-- In-Built Expertise END -->
                        </div>
                    </div>
                    <div class="col-lg-5 colWidth" style="height:65%">
                        <img class="" src="../../templateAssests/img/ckgallery/47.jpg" style="object-fit:cover;border-radius:10px;width:100%;height:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->
        

        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:0px 0 70px 0;" >
                    <div class="col-lg-5 colWidth">
                        <img class="" src="../../templateAssests/img/compliance-img.jpg" style="object-fit:cover;border-radius:10px;width:100%;height:100%;" alt="">
                        <!-- <img class="" src="../../templateAssests/img/ckgallery/47.jpg" height="700" style="width:80%;margin-left:17%;" alt="">  -->
                    </div>
                    <div class="col-lg-6 colWidth" style="margin-left:auto;">
                        <h2 class="poppins provideHead" style="color: #212121 ;font-size: 24px; font-weight: 600 ;line-height:35px;margin-bottom:35px;">Provide <span style="color:#f3766a; font-weight: 600;"> better value  </span> to our customers <span style="color:#f3766a; font-weight: 600;"> via technology.</span></h2>
                        <div class="row">
                           <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a ;font-weight:600;">Technology-Driven Competitive Advantage</h5>
                                <p class="foSection1_li_desc" >
                                   We leverage our technology to reduce administrative time, effort and service cost for corporates. With the widest range of corporate food service choices, employees enjoy an elevated food experience at work.
                                </p>
                            </div>
                            <!-- Technology-Driven Competitive Advantage end -->
                             <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a ;font-weight:600;">Digital Network</h5>
                                <p class="foSection1_li_desc" >
                                    CloudKitch is the only true online food services network that seamlessly connects corporates to food service operators and employees to delicious food@work. 
                                </p>
                            </div>
                            <!-- Digital Network END -->
                            <div class="col-12" style="margin-bottom:19px;">
                                <h5 class="foSection1_li_title" style="color:#f3766a;font-weight:600;">Integrated Platform</h5>
                                <p class="foSection1_li_desc">
                                    Powered by seamless administrative automation + digital engagement and discovery.
                                    CloudKitch’s API readiness and white labelled solutions allows corporates and service providers to integrate seamlessly with the platform.
                                </p>
                            </div>
                            <!-- Integrated Platform END -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->


        <div class="shape_element_2 bg_white"  style="padding:0px 0 0px 0;">
            <div class="container" >
                <div class="row " style="padding:0px 0 70px 0;" >
                    <div class="col-lg-12">
                        <h2 class="poppins align-center" style="color: #f3766a ;font-size: 24px; font-weight: 600 ;line-height:35px;margin-bottom:35px;">Leadership  <span style="color: #212121">Team</span></h2>
                        <div class="row">
                           <div class="col-12">
                                <p class="foSection1_li_desc align-center" style="margin-bottom: 0;">
                                   Our leadership team has deep domain expertise in pioneering food and workplace concepts in Indian and globally. We have over 100 years of collective experience and serving Top 500 Corporates in multiple locations.
                                </p>
                                <p class="foSection1_li_desc align-center">
                                   Our tech teams have diverse expertise in building ecosystem platforms, business intelligence tools, infrastructure and applications that are par excellence. We are determined to build products that our customers will love, our clients will value & our partners will benefit.
                                </p>
                                <p class="foSection1_li_desc align-center">
                                   We are backed by very renowned strategic advisory board and astute investors who believe in our vision and execution capabilities. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /shape_element_2 -->

        
  
    </main>
  <!-- /main -->


  

<loginRegModal />
<termsAndCondition />
<footerLayout style="display:block!important;"/>
    </div>
    <!-- /Root Div  -->
    
</template>

<!-- SPECIFIC CSS -->



<style scoped src="../../templateAssests/css/home.css"></style>
<style scoped>
 *{
     box-sizing: border-box;
 }
 .colWidth
{
    width:47%;   
}
 .image{
     float: left;
     width: 25%;
     padding: 5px;
 }
 
</style>
<style scoped>
div.a{
  text-align: justify; /* For Edge */
  -moz-text-align-last: center; /* For Firefox prior 58.0 */
  text-align-last: center;
}
div.b{
  text-align: justify; 
  }
  
  
div.next{
    float:left ;
    /* width: 100%; */
}
div.next2{
    float:right ;
    /* width: 100%; */
}
.ml-45
{
    margin-left: 45px!important;
}
.bannerTitle
{
    /* font-size: 34px!important;
    margin-bottom: 5px!important;
    font-family: 'Poppins', sans-serif;
    line-height: 51px !important;
    font-weight: 500 !important;
    color:#212121; */

    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
}
.bannerclrfont{
     font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
color: #212121;

}
.bannerDesc
{
    width: 71% !important;
    font-size: 22px!important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    /* color: #A4827D; */
    color: #663333;
}
.poppins
{
    font-family: 'Poppins', sans-serif !important;
}
.foSection1_li_title
{
    margin: 2px 0px;
    color: #212121;
    /* font-size: 16px; */
    font-size: 20px;
    /* font-weight: 600; */
    font-family: 'Poppins'!important;
     break-after: year;
}
.foSection1_li_desc
{
    /* color: #979797; */
    /* color: #663333; */
    color: #212121;
    font-family: 'Poppins'!important;
    /* font-size: 14px; */
    font-size: 16px;
}
.foSection1_li_desc1
{
    margin-bottom: 15px;
}

.bg_white
{
    background: #ffffff;
}
.emPanelSection
{
    width: 650px;
}
.header-video
{
    height:  430px !important;
}
.displayText
{
    padding-top: 62px;
    /* font-style: bold; */
}
.header-video img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
}
.headerCk
{
    position: fixed;
}
.contact{
        font-size:14px;
        color:#fff;
    }
    .contactAnch{
        color:#fff;
    }
    .contactSticky{
      color:#000!important;
      /* margin-top:15px; */
    }
    .contactSticky .contactAnch{
      color:#000;
    }
    .anchor:hover, .anchor:focus{
        color:#f3766a;
    }
    .a.btn_1:hover, .btn_1:hover{
        color:#fff;
    }
    .userNameSticky{
        color:#000!important;
    }
    .main-cards{
        position:relative;
        top:-145px;
        z-index:9;
        /* z-index:100; */
    }
    .cards{
        max-width:222px;
        max-height:245.43px;
        border-radius:5px;
    }
    .cardsRow{
        justify-content:center;
    }
    .opacity-mask{
        background-color:rgba(0, 0, 0, 0.5);
    }
    .ckLogo {
          bottom: 43px;
           position: relative;
           object-fit: cover;
           height: 100px;
    }
    #logo{
        padding-top:14px;
    }
    .mainLogoImg{
        width: 100%;
        object-fit: contain;
    }
    .mainLogoShow{
        display: block!important;
        margin-top: 10px;
    }
    .mainLogoHide{
        display: none;
    }
    .modal-body{
        padding:30px!important;
    }
    .modalLoginText{
        font-size:24px;
        margin-bottom: 14px;
        color:#212121;
    }
    .emailInput{
        margin-bottom: px;
    }
    .otpBtn{
        height: 45px;
        background: #FFc60c;
        border: none;
        color: #212121;
        margin-bottom: 37px;
    }
    .divider{
        color:#eaeaea;
        margin-bottom: 29px;
    }
    .google img{
        margin-right: 28px;
    }
    .google{
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 5px;
        color:#212121;
        font-size: 14px;
    }
    a.social_bt.google:hover, a.social_bt.google:focus{
        border: 1px solid #777777;
    }
    .googleLoginBtn
    {
        width: 100%;
        background: transparent;
        border:none;
    }
    .signUptext{
        color: #212121;
        font-size: 14px;
    }
    .signUptext span a{
        color: #F3766A!important;
    }
    .modal-footer{
        height:50px!important;
    }
    .otpSection{
        margin-top: 40px;
    }
    .otpText{
        margin-bottom: 10px;
        color: #212121;
        font-size:14px;
    }
    .contactNumber{
        font-size:16px;
        color:#212121;
        margin-bottom: 17px;
    }
    .contactNumber a{
         color: #F3766A!important;
         font-size:13px;
    }
    .enterOTP{
        color:#777777!important;
        font-size:14px;
        margin-bottom:14px;
    }
    .otpInputs{
        margin-bottom: 16px;
    }
    .resendOTP{
        color: #777;
        margin-bottom: 15px;
         width:80%;
    }
    .resendOTP .timer{
        color: #F3766A;
       
    }
    .verifyotpBtn{
        width: 170px;
        height: 45px;
        background: #FFC60C;
        border-radius: 5px;
        border:none;
        margin-bottom: 19px;
    }
    #signUpConditions li{
        display:inline;
        margin-right: 10px;
        font-size: 10px;
        text-decoration: underline;
    }
    header{
        z-index: 101!important;
    }
    .closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .inner-addon {
        position: relative;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
    .errorDiv{
        font-size:14px;
        color: #F3766A;
    }
    .right-addon .bxSearchModal { right: 8px;font-size:22px;}
    .right-addon input { padding-right: 30px; border-radius: 20px;}
    /* @media screen and (max-width: 767px) {
           .conatiner{
             padding-left:0;
             padding-right:0;
           }
    } */
     @media only screen and (min-width: 320px) and (max-width: 769px){
            .bannerDesc{
                width: 100% !important;
            }
            .colWidth
            {
                width:100%;   
            }
            .provideHead
            {
                margin-top: 35px;
            }
            .ml-45
            {
                margin-left: 0px!important;
            }
			 
		 }
    </style>
    <style>
    .dropdown-toggle{
	margin-right: 10px!important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu{
	    border-top: 3px solid #A4827D;
		width: 330px!important;
		max-width: 330px!important;
    }
    .user-menu{
        width: 144px!important;
        max-width: 144px!important;
        right: auto !important;
    }
    .dropdown-ul
    {
        list-style: none;
        padding: 0px!important;
    }
    .dropdown-ul li{
        padding: 12px 15px;
        color: #000;
    }
    .dropdown-ul li a{
        color: #000;
    }
    .dropdown-ul li a i{
        margin-right: 8px;
    }
    ul.dropdown-ul li a{
	    cursor: pointer;
    }
    .userPlaceImage
{
	object-fit: contain;
	border-radius: 25px;
}
.loginReg
{
	font-size: 18px;
	color: #F3766A;
}
.head_section
{
	margin-bottom: 20px;
}
/* .navigation_ul
{
	list-style-type: none;
	padding: 0;
} */
.navigation_ul li
{
	padding: 12px 0;
	font-size: 14px;
}
.navigation_ul li a
{
	color: #777;
}
.helpHead
{
	font-size: 16px;
	color: #777;
}
.login_reg
{
    display: none;
}
.login_reg a
{
    padding: 0 8px 10px;
}
.loginMob
{
    color: #fff;
}
.loginMob:hover, .loginMob:focus
{
    color: #F3766A!important;
}
.hideLogin_reg
{
    display: none !important;;
}
.findFood
{
    max-width: 122px;
    position: absolute!important;
    top: 0px;
    right: 5px;
}

.heightclass{
    height:360px !important;

}
.heightclassn{
    height:414px !important;

}
@media (max-width: 991px)
{
    .login_reg
    {
        display: block;
        position: absolute;
        left: 0px;
        top: 27px;
    }
    .findFood
    {
        margin: 0px 0 0 0!important;
        top: 5px;
        right: 15px;
    }

    .newview{
        width:100%;
    }


       .bannerTitle
{
   

font-size: 30px !important;
line-height: 30px !important;

}
.bannerclrfont{
    
font-size: 30px !important;
line-height: 30px !important;


}
.pdding{
    padding-top: 20px;
}


}

@media only screen and (max-width: 600px) {

    .newview{
        width:100% !important;
    }
  
    .bannerTitle
{
   

font-size: 25px !important;
line-height: 30px !important;

}
.bannerclrfont{
    
font-size: 25px !important;
line-height: 30px !important;


}

.heightclass{
    height:auto !important;
}
.heightclassn{
    height:auto !important;
}
.pdding{
    padding-top: 20px;
}

.newrtop{padding-top: 15px !important;}


}



@media (max-width: 1024px)
{
    #hero_video {
        background: #ededed url(../../templateAssests/img/ckgallery/banner02.jpg) center center no-repeat;
        /* background: #ededed url(../img/ckgallery/black.jpg) center center no-repeat; */
        background-size: cover;
        background-position: center;
    }

    .noMobilenew{
        visibility: hidden;
    }
    
}
  
    </style>
